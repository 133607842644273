import { styled } from '@mui/system'
import { Swiper } from 'swiper/react'
import { Container } from '@bluheadless/ui/src/particles/slider/slider.styled'

const shouldForwardProp = (prop) =>
	!['count', 'direction', 'heightVertical', 'thumbsCount', 'verticalObjectPosition'].includes(prop)

const Root = styled('div')`
	position: relative;
	max-width: 100%;
	margin: auto;

	${({ theme }) => theme.breakpoints.up('sm')} {
		max-width: 500px;
	}
	${({ theme }) => theme.breakpoints.up('lg')} {
		max-width: 100%;
	}
`

const TextSliderContainer = styled('div', { shouldForwardProp })`
	min-height: 0px;
	.swiper-button-next,
	.swiper-button-prev {
		padding: 0 !important;
		background: ${({ theme }) => theme.palette.primary.main};
		border: none;
		border-radius: 0;
		font-size: ${({ theme }) => theme.typography.pxToRem(18)};
		width: 15px;
		height: 18px;
		top: 28px !important;
		color: ${({ theme }) => theme.palette.primary.contrastText};
		display: none;
		${({ theme }) => theme.breakpoints.up('md')} {
			display: block;
		}
	}
	.swiper-button-next {
		right: 0 !important;
	}
	.swiper-button-prev {
		left: 0 !important;
	}
	${({ direction, heightVertical, verticalObjectPosition }) =>
		direction === 'vertical'
			? `
			.swiper-container {
				height: ${heightVertical};
				.swiper-slide {
					// first child of the slide
					> * {
						height: 100%;
						// second child of the slide (normally a link)
						> *:first-of-type {
							height: 100%;
							// third child of the slide (normally the parent of the picture)
							> div {
								padding-bottom: 0;
							}
						}
					}
					img {
						object-fit: cover;
						object-position: ${verticalObjectPosition};
					}
				}
			}
		`
			: ''}
	${Container} {
		--swiper-navigation-size: 60px;
	}
`

const SliderThumbs = styled(Swiper)`
	bottom: 4.17%;
	left: 4.17%;
	position: absolute;
	width: ${({ count }) => 'calc(' + 5 * count + '% + ' + 31 * (count - 1) + 'px)'};
	${({ theme }) => theme.breakpoints.up('md')} {
		width: ${({ count }) => 'calc(' + 9.56 * count + '% + ' + 31 * (count - 1) + 'px)'};
	}
	.swiper-slide {
		cursor: pointer;
		&:before {
			border: 1px solid rgba(255, 255, 255, 0.6);
			bottom: 0;
			content: '';
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;
		}
		&-thumb-active:before {
			border: 2px solid #fff;
		}
	}
`

export { TextSliderContainer, Root, SliderThumbs }
