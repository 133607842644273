import { Box } from '@mui/material'
import { cx } from '@emotion/css'
import TextSlider from '@/components/ui/organisms/text-slider'
import React, { Children } from 'react'
import { Slide } from '@bluheadless/ui/src/particles/slider'

const SliderText = ({ minHeight, sliderSettings, sx, cssProp, className, children }) => {
	return (
		<Box
			className={cx('Magento-PageBuilder-TextSlider', className)}
			sx={{ ...sx, minHeight, width: '100%' }}
			css={cssProp}
		>
			<TextSlider {...sliderSettings}>
				{Children.map(children, (child) => (
					<Slide>{child}</Slide>
				))}
			</TextSlider>
		</Box>
	)
}

export default SliderText
